import { Box, experimentalStyled as styled, Typography, BoxProps } from '@mui/material';

interface StyledErrorImgProps extends BoxProps {
  img: string;
  error: '500' | '401' | '404';
}

export const StyledWrapperPageNotFound = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.background.default,
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: '124px',
  lineHeight: '132px',
  paddingBottom: '16px',

  [theme.breakpoints.down('tabletM')]: {
    fontSize: '96px',
    lineHeight: '104px',
  },

  [theme.breakpoints.down('mobile')]: {
    fontSize: '80px',
    lineHeight: '88px',
  },
}));

export const StyledSubtitle = styled(Typography)(({ theme }) => ({
  paddingBottom: '12px',

  [theme.breakpoints.down('tabletM')]: {
    fontSize: '32px',
    lineHeight: '40px',
  },

  [theme.breakpoints.down('mobile')]: {
    fontSize: '24px',
    lineHeight: '28px',
    paddingBottom: '12px',
  },
}));

export const StyledTextContent = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  lineHeight: '28px',
  paddingBottom: '32px',

  [theme.breakpoints.down('tabletM')]: {
    fontSize: '18px',
    lineHeight: '21px',
  },

  [theme.breakpoints.down('mobile')]: {
    padding: '0 33px 32px',
  },
}));

export const StyledBlockPosition = styled(Box)(({ theme }) => ({
  maxWidth: '1248px',
  width: '100%',
  padding: '0 24px',
  margin: '60px auto 100px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: '1 0 auto',

  [theme.breakpoints.down('tabletM')]: {
    marginTop: '40px',
  },

  [theme.breakpoints.down('tabletS')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },

  [theme.breakpoints.down('mobile')]: {
    padding: '0 16px',
  },
}));

export const StyledContentWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '486px',
  width: '100%',

  [theme.breakpoints.down('laptop')]: {
    marginLeft: '20px',
    maxWidth: '350px',
  },

  [theme.breakpoints.down('tabletS')]: {
    textAlign: 'center',
    padding: '40px 0 0',
    marginLeft: 0,
  },
}));

export const StyledErrorImg = styled(Box)<StyledErrorImgProps>(({ theme, img, error }) => ({
  background: `center url(${img}) no-repeat`,
  backgroundSize: 'contain',
  width: '486px',
  height: error === '500' ? '485px' : '419px',

  [theme.breakpoints.down('tabletM')]: {
    width: '314px',
    height: error === '500' ? '314px' : '269px',
  },

  [theme.breakpoints.down('tabletS')]: {
    width: '295px',
    height: error === '500' ? '295px' : '253px',
  },
}));

export const StyledButton = styled(Box)(({ theme }) => ({
  '& .MuiButton-root': {
    width: '150px',
  },

  [theme.breakpoints.down('tabletS')]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));

export const StyledContent500Page = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  lineHeight: '28px',
  paddingBottom: '12px',

  [theme.breakpoints.down('tabletM')]: {
    fontSize: '18px',
    lineHeight: '21px',
  },

  [theme.breakpoints.down('mobile')]: {
    padding: '0 26px 12px',
  },
}));
