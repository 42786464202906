import React from 'react';
import { useTranslation } from 'react-i18next';

import { navigate } from 'gatsby';

import Button from 'src/components/common/Button/Button';
import Header from 'src/components/Header/Header';
import Footer from 'src/components/Landing/Footer/Footer';
import { Section } from 'src/constants';

import {
  StyledWrapperPageNotFound,
  StyledTitle,
  StyledTextContent,
  StyledSubtitle,
  StyledBlockPosition,
  StyledContentWrapper,
  StyledErrorImg,
  StyledButton,
} from './styled';
import Warning404Img from './warning401_404.svg';

const Error404 = () => {
  const { t } = useTranslation();

  const handleClickGoHomeButton = () => {
    navigate(`/#${Section.Top}`);
  };

  return (
    <StyledWrapperPageNotFound>
      <Header showOnlyHeaderPanel />
      <StyledBlockPosition>
        <StyledErrorImg img={Warning404Img} error="404" />
        <StyledContentWrapper>
          <StyledTitle variant="h1">{t('pageNotFound.title')}</StyledTitle>
          <StyledSubtitle variant="h2">{t('pageNotFound.subtitle')}</StyledSubtitle>
          <StyledTextContent variant="h5">{t('pageNotFound.content')}</StyledTextContent>
          <StyledButton onClick={handleClickGoHomeButton}>
            <Button>{t('pageNotFound.buttonText')}</Button>
          </StyledButton>
        </StyledContentWrapper>
      </StyledBlockPosition>
      <Footer />
    </StyledWrapperPageNotFound>
  );
};

export default Error404;
