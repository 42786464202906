import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';
import { Link, navigate } from 'gatsby';

import Button from 'src/components/common/Button/Button';
import Iconography from 'src/components/common/Iconography';
import { StyledFab, StyledBackgroundGradient } from 'src/components/Landing/LandingHeader/styled';
import { handleClickOnLoginButton, handleScrollToTop, Section } from 'src/constants';
import { useWindowWidth } from 'src/hooks/useWindowWidth';
import logoImage from 'src/images/logo.svg';
import { Routes } from 'src/types';

import { DeviceSizes } from '../Landing/types';

import {
  MainTitle,
  StyledWrapperHeaderWithContent,
  StyledHeaderTab,
  StyledWrapperHeader,
  StyledWrapperHeaderTab,
  StyledLogoLink,
  StyledCloseBurgerMenu,
  StyledHederBlock,
  StyledHederPosition,
  StyledHeaderContainer,
  StyledBackdrop,
  StyledOpenBurgerMenuWrapper,
  HeaderButtonContainer,
  LogInButton,
} from './styled';

interface HeaderProps {
  title?: string;
  showOnlyHeaderPanel?: boolean;
}

const Header = ({ title, showOnlyHeaderPanel }: HeaderProps) => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();

  const [showHeader, setShowHeader] = useState<boolean>(false);
  const [headerContentBlockHeight, setHeaderContentBlockHeight] = useState<number>();
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState<boolean>(false);
  const BACKGROUND_HEADER_CONTENT_HEIGHT = (headerContentBlockHeight as number) / 2;

  const handleShowFixedHeader = useCallback(() => {
    setShowHeader(window.scrollY >= BACKGROUND_HEADER_CONTENT_HEIGHT);

    const header = document.getElementById('header') as HTMLElement;
    const headerOffsetHeight = header.offsetHeight;
    const permissibleScrollHeight = BACKGROUND_HEADER_CONTENT_HEIGHT + headerOffsetHeight;

    if (showHeader) {
      if (window.scrollY >= BACKGROUND_HEADER_CONTENT_HEIGHT && window.scrollY <= permissibleScrollHeight) {
        header.style.top = `-${permissibleScrollHeight - window.scrollY}px`;
      }
      if (window.scrollY >= permissibleScrollHeight) {
        header.style.top = '0px';
      }
    }
  }, [showHeader, BACKGROUND_HEADER_CONTENT_HEIGHT]);

  const handleBurgerMenu = () => {
    setIsBurgerMenuOpen(!isBurgerMenuOpen);
  };

  useEffect(() => {
    if (windowWidth >= DeviceSizes.laptopM && isBurgerMenuOpen) {
      setIsBurgerMenuOpen(false);
    }
  }, [windowWidth, isBurgerMenuOpen]);

  useEffect(() => {
    const headerContentHeight = document.getElementById('header-content');

    setHeaderContentBlockHeight(headerContentHeight?.offsetHeight);

    document.addEventListener('scroll', handleShowFixedHeader);

    return () => document.removeEventListener('scroll', handleShowFixedHeader);
  }, [handleShowFixedHeader]);

  const handleFreeAccessButtonClick = async () => {
    setIsBurgerMenuOpen(false);
    await navigate(`/#${Section.Access}`);
  };

  const headerButtons = () => (
    <>
      <HeaderButtonContainer>
        <LogInButton onClick={handleClickOnLoginButton} variant="contained">
          {t('landingPage.header.logInButton')}
        </LogInButton>

        <Button onClick={handleFreeAccessButtonClick} fullWidth variant="contained">
          {t('landingPage.header.freeAccessButton')}
        </Button>
      </HeaderButtonContainer>
    </>
  );

  const headerPanel = () => (
    <>
      <StyledWrapperHeaderTab isBurgerMenuOpen={isBurgerMenuOpen}>
        <Link to={`/#${Section.About}`} onClick={() => setIsBurgerMenuOpen(false)}>
          <StyledHeaderTab>{t('landingPage.header.anchorMenu.about')}</StyledHeaderTab>
        </Link>
        <Link to={`/#${Section.Access}`} onClick={() => setIsBurgerMenuOpen(false)}>
          <StyledHeaderTab>{t('landingPage.header.anchorMenu.access')}</StyledHeaderTab>
        </Link>
        <Link to={`/#${Section.Faq}`} onClick={() => setIsBurgerMenuOpen(false)}>
          <StyledHeaderTab>{t('landingPage.header.anchorMenu.faq')}</StyledHeaderTab>
        </Link>
      </StyledWrapperHeaderTab>
      <StyledCloseBurgerMenu onClick={handleBurgerMenu} isBurgerMenuOpen={isBurgerMenuOpen}>
        <Iconography iconName={isBurgerMenuOpen ? 'cancel' : 'burger-menu'} htmlColor="text.primary" />
      </StyledCloseBurgerMenu>
    </>
  );

  return (
    <StyledWrapperHeaderWithContent showOnlyHeaderPanel={showOnlyHeaderPanel}>
      <Box height="82px">
        <StyledHederBlock id="header" sticky={showHeader && !showOnlyHeaderPanel}>
          <StyledWrapperHeader>
            <StyledHederPosition>
              <StyledLogoLink to={Routes.Main}>
                <img src={logoImage} alt="logo" />
              </StyledLogoLink>
              {headerPanel()}
              {!isBurgerMenuOpen && windowWidth >= DeviceSizes.laptopM && headerButtons()}
            </StyledHederPosition>
          </StyledWrapperHeader>
        </StyledHederBlock>
      </Box>

      <StyledHeaderContainer id="header-content">
        {!showOnlyHeaderPanel && (
          <>
            <StyledBackgroundGradient />
            <MainTitle variant="h1">{title}</MainTitle>
          </>
        )}
      </StyledHeaderContainer>

      {isBurgerMenuOpen && (
        <>
          <StyledBackdrop />
          <StyledOpenBurgerMenuWrapper>
            {headerPanel()}
            {headerButtons()}
          </StyledOpenBurgerMenuWrapper>
        </>
      )}

      {showHeader && !showOnlyHeaderPanel && (
        <StyledFab onClick={handleScrollToTop} id="scroll-to-top-button">
          <Iconography iconName="chevron-arrow-right" fontSize="large" />
        </StyledFab>
      )}
    </StyledWrapperHeaderWithContent>
  );
};

export default Header;
