import { graphql } from 'gatsby';

import withGlobalStyles from 'src/components/GlobalStyleHOC';

// TODO: Uncomment when the feature is ready (It will replace the 404 error)
// import ContactUsView from 'src/components/Landing/ContactUsView/contactUsView';
import Error404 from '../404';

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

// TODO: Uncomment when the feature is ready (It will replace the 404 error)
// export default withGlobalStyles(ContactUsView);

export default withGlobalStyles(Error404);
